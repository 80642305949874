import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { companyLogo, Logouticon, HC_top_logo} from "../../assets";
import { logout } from "../../store/authSlice"; // Redux logout action
import useAuth from "../../hooks/useAuth";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { isAuthenticated } = useSelector((state) => state.auth);
  const { clearToken } = useAuth();

  // Redirect to login if not authenticated
  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate("/login",);
  //   }
  // }, [isAuthenticated, navigate]);

  // Handle logout
  const handleLogout = () => {
    clearToken();
    dispatch(logout()); // Dispatch Redux logout action
    navigate("/login")
  };

  return (
    <div className="">
      <nav className="bg-slate-100  fixed top-0 w-full flex flex-col sm:flex-row justify-center md:justify-between shadow-xl ">
        <div className="sm:ml-4 lg:ml-9">
          {/* <img className="h-10 mt-1 mb-1" src={companyLogo} alt="logo" onClick={()=> navigate(`/home`)}/> */}
          <img className="h-12 " src={HC_top_logo} alt="logo" onClick={()=> navigate(`/home`)}/>
        </div>
        <div className="sm:ml-auto sm:mr-4 mt-2 sm:mt-1.5 sm:pl-10 flex" onClick={handleLogout}>
          <h1 className="mt-1 mr-2 text-green-950 font-sm font-semibold cursor-pointer" onClick={handleLogout}>Logout</h1>
          <div className="text-center">
            <img
              src={Logouticon}
              alt="logout"
              className="h-6 mt-1 mr-6 cursor-pointer"
              onClick={handleLogout}
            />
          </div>
        </div>
      </nav>
    </div>

  );
};

export default Navbar;
