import React, { useEffect, useState } from "react";
import Modelbox from "../../utilityComponents/Modelbox";
import InvoicesAccordionHeader from "./InvoicesAccordionHeader";
import InvoiceAccordionExpanded from "./InvoiceAccordionExpanded";
import { useParams } from "react-router-dom";
import { setLoadingBarStatus } from "../../../store/loadingSlice";
import { useDispatch } from "react-redux";
import InvoiceStatus from "../../utilityComponents/InvoiceStatus";

const Invoices = (invoice) => {
  const [isAExpanded, setIsAExpanded] = useState(false);
  const [isBExpanded, setIsBExpanded] = useState(false);
  const [isCExpanded, setIsCExpanded] = useState(false);

  const [disableA, setDisableA] = useState(true);
  const [disableB, setDisableB] = useState(true);
  const [disableC, setDisableC] = useState(true);

  const [editModeA, setEditModeA] = useState(false); // New state for editMode
  const [editModeB, setEditModeB] = useState(false); // New state for editMode
  const [editModeC, setEditModeC] = useState(false); // New state for editMode

  const [requestId, setRequestId] = useState("");
  const [jobData, setJobData] = useState([]);
  const [update, setUpdate] = useState(false);

  const { jobId, project } = useParams();
  const dispatch = useDispatch();

  // accordian expand condition based on the invoice type
  const accordianExpanded = (type) => {
    if (type === "A") {
      setIsAExpanded(!isAExpanded);
    } else if (type === "B") {
      setIsBExpanded(!isBExpanded);
    } else if (type === "C") {
      setIsCExpanded(!isCExpanded);
    }
  };

  // setting model dialogue box initally false
  const [isModalOpen, setIsModalOpen] = useState(false);
  // setting the dialogue box open when arrow is closed
  const openModal = () => setIsModalOpen(true);

  // function for closing 
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // dialogue box open based on the invoice type
  const renderModalBox = (type) => {
    if (type === "A" && !isAExpanded) {
      return <Modelbox isOpen={isModalOpen} openModal={openModal} />;
    } else if (type === "B" && !isBExpanded) {
      return <Modelbox />;
    } else if (type === "C" && !isCExpanded) {
      return <Modelbox />;
    }
    return null;
  };

  const saveChanges = async (updateData, index) => {
    console.log("update", updateData)
    // let index = null;
    //take type from updateData
    try {
      dispatch(setLoadingBarStatus({ show: true })); // Show loading bar
      let updateStatus;
      if (updateData && updateData.invoices[index].invoiceType === "A") {
        const responseJson = await fetch(`/api/invoices/typeA/${jobId}/${project}`, {
          method: "PATCH",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ job: updateData, index: index }),
        });
        const response = await responseJson.json();
        console.log(response);
        updateStatus = response.status;
        if (updateStatus === 200) {
          setRequestId(response.data.requestId)
          // setUpdate(!update);
        }
      } else if (updateData && updateData.invoices[index].invoiceType === "B") {
        const responseJson = await fetch(`/api/invoices/typeB/${jobId}/${project}`, {
          method: "PATCH",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ job: updateData, index: index }),
        });
        const response = await responseJson.json();
        updateStatus = response.status;
        if (updateStatus === 200) {
          setRequestId(response.data.requestId)
          // setUpdate(!update);
        }
      } else if (updateData && updateData.invoices[index].invoiceType === "C") {
        const responseJson = await fetch(`/api/invoices/typeC/${jobId}/${project}`, {
          method: "PATCH",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ job: updateData, index: index }),
        });
        const response = await responseJson.json();
        updateStatus = response.status;
        if (updateStatus === 200) {
          setRequestId(response.data.requestId)
          setUpdate(!update);
        }
      }

    } catch (error) {
      console.log("error", error)
    }

  };

  useEffect(() => {
    //fetch invoice data of this job
    // showing loading bar while data processing
    dispatch(setLoadingBarStatus({ show: true }));
    async function getJobsById() {
      const response = await fetch(`/api/jobs/job/${jobId}`);
      const jobsResponse = await response.json();

      setJobData(jobsResponse.data.job);
      console.log("jobsResponse", jobsResponse.data.job);
      // showing loading bar false after data is sucessfully fetched
      dispatch(setLoadingBarStatus({ show: false }));
      // setJobData(jobsResponse.jobs)
    }
    getJobsById();
  }, [update]);

  return (
    <div>
      {jobData.invoices?.length > 0 &&
        jobData.invoices?.map((invoice, i) => {
          const isExpanded =
            invoice.invoiceType === "A"
              ? isAExpanded
              : invoice.invoiceType === "B"
                ? isBExpanded
                : isCExpanded;

          const setIsExpanded =
            invoice.invoiceType === "A"
              ? setIsAExpanded
              : invoice.invoiceType === "B"
                ? setIsBExpanded
                : setIsCExpanded;

          const setDisable =
            invoice.invoiceType === "A"
              ? setDisableA
              : invoice.invoiceType === "B"
                ? setDisableB
                : setDisableC;

          const editMode =
            invoice.invoiceType === "A"
              ? editModeA
              : invoice.invoiceType === "B"
                ? editModeB
                : editModeC;

          const setEditMode =
            invoice.invoiceType === "A"
              ? setEditModeA
              : invoice.invoiceType === "B"
                ? setEditModeB
                : setEditModeC;

          return (
            <div key={i}>
              <InvoicesAccordionHeader
                job={jobData}
                //sending the index through props 
                numberKey={i}
                closeModal={closeModal}
                //open and close model dialogue box for type A
                renderModalBox={renderModalBox}
                openModal={openModal}
                //accodian expand and close
                toggle={(i) => {
                  accordianExpanded(i);
                  renderModalBox(i);
                }}
                setIsExpanded={setIsExpanded}
                setDisable={setDisable}
                editMode={editMode}
                setEditMode={setEditMode}
              />
              {isExpanded && (
                <InvoiceAccordionExpanded
                  job={jobData}
                  numberKey={i}
                  disable={
                    invoice.invoiceType === "A"
                      ? disableA
                      : invoice.invoiceType === "B"
                        ? disableB
                        : disableC
                  }
                  saveChanges={saveChanges}
                  closeView={() => accordianExpanded(invoice.invoiceType)}
                />
              )}
            </div>
          );
        })}
      <Modelbox
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      {requestId ? (
        <InvoiceStatus
          requestId={requestId}
          setRequestId={setRequestId}
          setUpdate={() => {
            setUpdate(!update);
            setIsModalOpen(false);
            setRequestId(null);
          }}
        />
      ) : null}
    </div>
  );
};

export default Invoices;

