import { useNavigate, useParams } from "react-router-dom";
import "boxicons";
import Navbar from "../../utilityComponents/Navbar.jsx"
import Headerjobs from "./Headerjobs.jsx";
import Invoices from "./Invoices.jsx";
import InvoicesTableheader from "./InvoicesTableheader.jsx"
import { useDispatch } from "react-redux";

const Invoicespage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollWrapperStyle = {
    maxHeight: '940px',
    // overflowX: 'auto',
    scrollbarColor: " #e1e2e3 #ffffff",
    
  }
  return (
    <div className="font-fontFamily overflow-y-scroll no-scrollbar " style={scrollWrapperStyle} >
      <div className="mb-10">
        <Navbar/>
      </div>
      <div>
        <Headerjobs />
      </div>
      <InvoicesTableheader />
      <div>
        <Invoices />
      </div>
    </div>
  );
};
export default Invoicespage;
