import React from "react";
import Modal from "react-modal";
import { useState } from "react";
import { cross } from "../../assets";
const Modelbox = ({ isModalOpen, onClose }) => {
  console.log("isModalOpen", isModalOpen);
  //   const openModal = () => setIsModalOpen(true);
  //   const closeModal = () => setIsModalOpen(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <>
      <Modal isOpen={isModalOpen} onClose={""} style={customStyles}>
        <div className="font-fontFamily">
          <div className="border-b-2 border-orange-500  mt-2">
            <div className="flex text-left mb-1.5">
              <h1 className="text-lg font-semibold mb-1.5">
                Are you sure you want to go back?
              </h1>
              <button className="">
                <img
                  src={cross}
                  onClick={onClose}
                  className="h-4 ml-80"
                  alt="cross"
                ></img>
              </button>
            </div>
          </div>
          <div className="mt-3.5">
            <h2>This field have unsaved Changes</h2>
          </div>
          <div className="flex  justify-end space-x-1 mt-5">
            <button className="text-lg px-5 text-gray-500" onClick={onClose}>
              Cancel
            </button>
            <button
              className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700  shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 text-white rounded-full px-5 py-1.5 text-md"
              onClick={onClose}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Modelbox;
