import React from "react";

const InvoicesTableheader = () => {
  return (
<div>
{/* <div className="font-semibold text-lg border-b-2 border-orange-500 mt-10 py-5 flex flex-wrap text-left ml-10 mr-10">
  <div className="w-full sm:w-auto lg:ml-2 lg:mr-14 sm:ml-4">Invoice Type</div>
  <div className="w-full sm:w-auto lg:ml-52 lg:mr-16 sm:ml-4">Invoice Name</div>
  <div className="w-full sm:w-auto lg:ml-52 lg:mr-16 sm:ml-4">Created Date</div>
  <div className="w-full sm:w-auto lg:ml-52 lg:mr-14 sm:ml-4">Status</div>
  <div className="w-full sm:w-auto lg:ml-60 lg:mr-10 sm:ml-4">Actions</div>
</div> */}
<div className=" font-semibold text-lg border-b-2 border-orange-500 mt-10 ml-10 mr-10  sm:overflow-x-auto">
 <div className="grid grid-flow-col mb-5">
 <div className="w-auto">Invoice Type </div>
 <div className="">Invoice Name</div>
  <div className="">Created Date</div>
 <div>Status</div>
  <div>Actions</div>
 </div>
</div>
</div>

  );
};
export default InvoicesTableheader;

