import React, { useEffect, useState } from "react";
import { Toast } from "../../assets";
import { cross } from "../../assets";
import { useNavigate } from "react-router-dom";

const Toaster = () => {
  const [visible, setIsvisible] = useState(true);
  // const close = () => {
  //   setIsvisible(false);
  // };
  useEffect(() => {
    setTimeout(() => {
      setIsvisible(false);
    }, 6000);
  }, []);

  return (
    <div>
      {visible && (
        <div className="flex justify-between mr-10 font-fontFamily">
          <div className="flex  shadow-2xl py-1.5 rounded-md">
            {/* <ToastContainer /> */}
            <div className="ml-2 mt-2.5">
              <img src={Toast} className="h-10"></img>
            </div>
            <div className="flex-none mt-1.5 ml-5">
              <h1 className="font-semibold">Invalid Input</h1>
              <p className="mb-1 text-gray-400">Entered Input is invalid</p>
            </div>
            <div>
              <img
                src={cross}
                className="h-5 mt-4  ml-20 mr-3"
                // onClick={close}
              ></img>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Toaster;
