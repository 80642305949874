

import { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import { framecross } from "../../../assets";
import DropDown from "../../utilityComponents/DropDown";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(utc);


function ReadExcel(props) {
  const { close, uploadAndGenerateInvoice, data } = props;
  const [type, setType] = useState("");
  const excelDateToJSDate = (serial) => {
    const baseDate = new Date(1900, 0, 1);
    const correctedSerial = serial - 1;
    return new Date(baseDate.getTime() + correctedSerial * 24 * 60 * 60 * 1000);
  };

  const columns = [
    {
      name: "Katapult",
      selector: (row) => row.Katapult,
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => row.County,
      sortable: true,
    },
    {
      name: "Area",
      selector: (row) => row.Area,
      sortable: true,
    },
    // {
    //   name: <div>Job name</div>,
    //   selector: (row) => row.jobname,
    //   sortable: true,
    // },
    {
      name: "Route",
      selector: (row) => row.Route,
      sortable: true,
    },
    {
      name: "Hub",
      selector: (row) => row.Hub,
      sortable: true,
    },
    {
      name: <div>Work order</div>,
      selector: (row) => row["Work  Order"],
      sortable: true,
    },
    {
      name: <div>Designs</div>,
      cell: (row) => <div style={{ maxWidth: '600px' }}>{row['Designs']}</div>,
      // <div style={{ wordWrap: "break-word",
      //   overflowWrap: "break-word", 
      //   maxWidth: "200px",}}>{ row["Designs"]}</div>,
      minWidth: '150px',
      sortable: true,
    },
    {
      name: <div>Work Location</div>,
      selector: (row) => row["Work Location"],
      sortable: true,
    },
    // {
    //   name: <div>Tentactive footage</div>,
    //   selector: (row) => row.tenactive,
    //   sortable: true,
    // },
    {
      name: <div>Final footage</div>,
      selector: (row) => row["Final Footage(feet)"],
      sortable: true,
    },
    {
      name: <div>100% Footage</div>,
      selector: (row) => row["100% Footage"],
      sortable: true,
    },
    {
      name: "Rate",
      selector: (row) => row["Rate"],
      sortable: true,
    },
    {
      name: "Inv. No",
      selector: (row) => row["Inv. No"],
      sortable: true,
    },
    {
      name: "Inv. Dt",
      selector: (row) => {
        const serialDate = row["Inv. Dt"];
        // const jsDate = excelDateToJSDate(serialDate);
        // console.log(jsDate);
        // console.log(jsDate.toISOString());
        const formattedDateWithTime = dayjs(excelDateToJSDate(serialDate)).utc().format('YYYY-MM-DD');
        return formattedDateWithTime
      },
      sortable: true,
    },
    {
      name: <div>Kind Attention</div>,
      cell: (row) => row["Kind Attention"],
      sortable: true,
      minWidth: '210px',
    },
  ];


  const customStyles = {

    rows: {
      style: {
        minHeight: "70px",
        border: "none",
        display: "flex",
        alignItems: "center",

      },
    },
    headCells: {
      style: {
        color: "black",
        fontWeight: "",
        padding: "9px",
        paddingLeft: "8px",
        // paddingRight: "8px",
        fontSize: "15px",
        text: "black",
        backgroundColor: "#e8f4f8",
        border: "200px",
        justifyContent: "left",
        textAlign: "left",
      },
    },
    cells: {
      style: {
        width: '100px',
        padding: "10px",
        backgroundColor: "#FFFFFF",
        color: "black",
        border: "none",
        borderBottom: "none",
        textAlign: "center",
        lineHeight: "normal",
      },
    },
    // designColumn: {
    //   style: {
    //     overflowWrap: "break-word", 
    //     wordWrap: "break-word",      
    //     whiteSpace: "normal",      
    //   },
    // },
  };

  const scrollWrapperStyle = {
    maxHeight: '300px',
    overflowX: 'auto',
    scrollbarColor: " #cbeaf5 #ffffff",
  }

  const options = [
    { label: "select type", value: "" },
    { label: "A", value: "typeA" },
    { label: "B", value: "typeB" },
    { label: "C", value: "typeC" },
  ];
  return (
    <div className="p-4 font-fontFamily">
      <div className="fixed inset-0 flex items-center justify-center  bg-black bg-opacity-30">
        <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-2/3">
          <div className="flex justify-between">
            <h3 className="text-lg font-semibold mb-8 mt-4">
              View and Generate file
            </h3>
            <button onClick={close}>
              <img src={framecross} className="h-6 mb-1" alt="cross"></img>
            </button>
          </div>
          <div className="lg:flex md:flex mb-8 ">
            <div>

              {/* <h1 className="font-semibold mb-2">Type</h1> */}
              <div className="flex items-center">
                <DropDown
                  onChange={(e) => {
                    console.log(e.target.value);
                    setType(e.target.value);
                  }}
                  value={type}
                  options={options}
                  className="p-2 pr-16 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 mt-2.5"
                />
                <span className="text-red-500 pl-2">*</span>
              </div>
            </div>

          </div>
          <div style={scrollWrapperStyle} className="rounded-sm shadow-lg ">
            <DataTable
              width='100%'
              className="text-wrap"
              style={{ overflow: 'wrap' }}
              columns={columns}
              data={data}
              // selectableRows
              sortable
              customStyles={customStyles}
              box-shadow
              fixedHeader
              fixedHeaderScrollHeight="300px"

            />
          </div>
          <div className=" flex justify-end mt-4 mb-2">
            {/* <div className="">
              <button
                onClick={() => {
                  if (!type) {
                    alert("type not selected");
                    return;
                  }
                  uploadAndGenerateInvoice(type);
                }}
                className=" mt-2 px-4 py-1  rounded-sm mr-4 mb-2 text-blue-500 text-sm border-solid border-2 border-blue-500"
              >
                Generate & download
              </button>
            </div> */}
            <button
              onClick={() => {
                if (!type) {
                  alert("type not selected");
                  return;
                }
                uploadAndGenerateInvoice(type);
              }}
              className=" bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700  shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 shadow-lg mt-2  px-8  py-2 mr-2 text-white mb-2 rounded-sm text-sm">
              Generate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReadExcel;
