import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import ReadExcel from "./ReadExcel";
import {
  companyLogo,
  line,
  Search,
  Downloadblue,
  HC_top_logo,
} from "../../../assets";
import Navbar from "../../utilityComponents/Navbar";
import { setLoadingBarStatus } from "../../../store/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import InvoiceStatus from "../../utilityComponents/InvoiceStatus";
import { Back } from "../../../assets";
import toast, { Toaster } from 'react-hot-toast';

const Jobs = () => {

  const columns = [
    {
      name: "Katapult",
      selector: (row) => row.jobId,
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
    },
    {
      name: "Area",
      selector: (row) => row.area,
      sortable: true,
    },
    {
      name: <div>Job name</div>,
      selector: (row) => row.jobName,
      sortable: true,
    },
    {
      name: "Route",
      selector: (row) => row.route,
      sortable: true,
    },
    {
      name: "Work order",
      selector: (row) => row.workOrder,
      sortable: true,
    },
    {
      name: <div className="ml-3">Action</div>,
      cell: (row) => (
        <div className="flex">
          <button onClick={() => navigate(`/invoice/${project}/${row.jobId}`)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.41421 15.89L16.5563 5.74786L15.1421 4.33364L5 14.4758V15.89H6.41421ZM7.24264 17.89H3V13.6473L14.435 2.21232C14.8256 1.8218 15.4587 1.8218 15.8492 2.21232L18.6777 5.04075C19.0682 5.43127 19.0682 6.06444 18.6777 6.45496L7.24264 17.89ZM3 19.89H21V21.89H3V19.89Z"
                fill="#008DFE"
              />
            </svg>
          </button>
          <button
            onClick={() => {
              downloadInvoices(row.jobId);
            }}
          >
            <img
              src={Downloadblue}
              className="h-6 hover:h-6 ml-2"
              alt="download"
            ></img>
          </button>
        </div>
      ),
    },
    {
      name: 'Invoice Status',
      cell: (row) => {
        const invoiceTypes = row.invoices.map(invoice => invoice.invoiceType);
        return (
          <div className="flex ml-1">
            <button className={`px-2 py-1.5 rounded-full mr-1.5 font-semibold cursor-default ${invoiceTypes.includes('A') ? 'bg-sky-800 text-white' : 'bg-slate-100 text-sky-800'}`}> A </button>
            <button className={`px-2 rounded-full mr-1.5 font-semibold cursor-default ${invoiceTypes.includes('B') ? 'bg-sky-800 text-white' : 'bg-gray-100  text-sky-800'}`} > B </button>
            <button className={`px-2 rounded-full mr-1.5 font-semibold cursor-default ${invoiceTypes.includes('C') ? 'bg-sky-800 text-white' : 'bg-gray-100  text-sky-800'}`}>C</button>
          </div>
        );
      }
    }

  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "60px",
        border: "none",
        display: "flex",
        alignItems: "center",
      },
    },
    headCells: {
      style: {
        backgroundColor: "",
        color: "black",
        fontWeight: "",
        padding: "9px",
        paddingLeft: "8px",
        fontSize: "15px",
        text: "black",
        borderBottom: "2px solid orange",
        justifyContent: "left",
        wordWrap: "break-word",
        textAlign: "left",
      },
    },
    cells: {
      style: {
        padding: "10px",
        backgroundColor: "#FFFFFF",
        color: "black",
        border: "none",
        borderBottom: "none",
        textAlign: "center",
        lineHeight: "normal",
      },
    },
  };

  const navigate = useNavigate();
  const [exceldata, setExcelData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [update, setUpdate] = useState(false);
  const [jobData, setJobData] = useState([]);
  // const [filteredRecords, setFilteredRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentSearchQuery, setCurrentSearchQuery] = useState(""); // Stores typed query
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const { project } = useParams();
  const dispatch = useDispatch();
  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10); // Number of records per page
  const [currentPage, setCurrentPage] = useState(1); // Start with page 1
  const { projectNames, selectedProject } = useSelector(
    (state) => state.projects
  );

  const toggleSelection = (jobId) => {
    const selectedRows = jobId.selectedRows;
    const selectedJobIdsArray = selectedRows.map((row) => row.jobId); // Extract all job IDs
    setSelectedJobIds(selectedJobIdsArray);
  };

  // const toggleSelection = (jobId) => {
  // // Combine selected rows with the new selected job ID
  // const selectedRows = jobId.selectedRows;
  // const selectedRowsArray = selectedRows.map((row) => row.jobId);
  // const selectedJobIdsArray = [...selectedJobIds, ...selectedRowsArray];
  // // Extract job IDs from the selected rows
  // // Update the state with the new job IDs array
  // setSelectedJobIds(selectedJobIdsArray);
  // };

  console.log(selectedJobIds);
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      alert("No file selected!");
      return;
    }

    const validTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    if (!validTypes.includes(file.type)) {
      alert("Please upload a valid Excel file.");
      return;
    }
    setFile(file);
    const reader = new FileReader();
    dispatch(setLoadingBarStatus({ show: true }));
    reader.onload = (e) => {
      try {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "array" });

        // Parse the first sheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        console.log("Excel data:", jsonData);
        setExcelData(jsonData);
        setIsModalOpen(true);
      } catch (error) {
        console.error("Error reading Excel file:", error);
        alert("There was an error processing the file. Please try again.");
      } finally {
        dispatch(setLoadingBarStatus({ show: false }));
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const excelFileUpload = () => {
    const inp = document.createElement("input");
    inp.type = "file";
    inp.accept = ".xlsx";
    inp.enctype = "multipart/form-data";
    inp.addEventListener("change", function (event) {
      handleFileUpload(event);
    });
    inp.click();
  };

  function closeModel() {
    setIsModalOpen(false);
  }

  const downloadInvoices = async (jobId) => {
    try {
      const response = await fetch(`/api/jobs/job/download/${jobId}`);
      if (!response.ok) throw new Error("Error downloading the ZIP file");

      const blob = await response.blob();
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = "invoice.zip"; // Default filename

      // Extract filename from Content-Disposition header
      if (contentDisposition && contentDisposition.includes("filename=")) {
        filename = contentDisposition.split("filename=")[1].replace(/"/g, "");
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename; // Use the dynamic filename
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the ZIP file:", error);
    }
  };

  const downloadInvoicesByProject = async (project) => {
    try {
      const response = await fetch(`/api/project/download/${project}`);
      if (!response.ok) throw new Error("Error downloading the ZIP file");

      const blob = await response.blob();
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = "invoice.zip"; // Default filename

      // Extract filename from Content-Disposition header
      if (contentDisposition && contentDisposition.includes("filename=")) {
        filename = contentDisposition.split("filename=")[1].replace(/"/g, "");
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename; // Use the dynamic filename
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the ZIP file:", error);
    }
  };

  const uploadAndGenerateInvoice = async (type) => {
    if (!file) {
      alert("Please upload a file first!");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    const uploadInvoice = async (url) => {
      try {
        dispatch(setLoadingBarStatus({ show: true })); // Show loading bar
        const responseJson = await fetch(url, {
          method: "POST",
          body: formData,
        });

        const response = await responseJson.json();
        console.log(response);
        if (response.status === 201) {
          //now check for status for the batch
          setRequestId(response.data.requestId);
          const noNeedInvoicesCount = response?.data?.noNeedInvoices?.length;
          if (noNeedInvoicesCount && noNeedInvoicesCount > 0) {
            if (noNeedInvoicesCount === 1) {
              toast(`${noNeedInvoicesCount} invoice footage is greater than A`);
            }
            else {
              toast(`${noNeedInvoicesCount} invoices footage are greater than A`);
            }
          }
          // setUpdate(!update);
        } else {
          alert("Failed to upload file!");
          dispatch(setLoadingBarStatus({ show: false }));
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        dispatch(setLoadingBarStatus({ show: false }));
        alert("Error uploading file!");
      }
    };

    if (type === "typeA") {
      uploadInvoice(`/api/invoices/typeA/Lumos`);
    } else if (type === "typeB") {
      uploadInvoice(`/api/invoices/typeB/Lumos`);
    } else {
      uploadInvoice(`/api/invoices/typeC/Lumos`);
    }
  };

  // Function to fetch records data based on the page number
  const fetchPaginationData = async (page, newPerPage = perPage) => {
    try {
      dispatch(setLoadingBarStatus({ show: true })); // Show loading bar;
      const response = isSearchClicked ? await fetch(`/api/jobs/${project}?page=${page}&records=${newPerPage}&search=${searchQuery}`) :
        await fetch(`/api/jobs/${project}?page=${page}&records=${newPerPage}`);
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      const jobsResponse = await response.json();

      if (jobsResponse.status === 200) {
        setRecords(jobsResponse.data.projects[0].jobs);
        setTotalRows(jobsResponse.data.totalJobs); // Total number of records (used for pagination)
      }
      // setData(response.data.data); // Set data for the current page
    } catch (error) {
      console.error("Error fetching pagination data:", error);
    } finally {
      dispatch(setLoadingBarStatus({ show: false })); // Hide loading bar
    }
  };

  // const handleSearch = (query) => {
  // setSearchQuery(query);
  // const lowercasedQuery = query.toLowerCase();
  // const filtered = jobData.filter((item) =>
  // ["jobId", "country", "jobName", "area", "workOrder"]
  // .map((key) => item[key]?.toLowerCase().includes(lowercasedQuery))
  // .some(Boolean)
  // );
  // setFilteredRecords(filtered);
  // }
  // Handler for page change (pagination)

  const handleSearch = () => {
    setSearchQuery(currentSearchQuery);
    setIsSearchClicked(true); // Set the search flag
    setCurrentPage(1); // Reset the page to 1
    // fetchPaginationData(1);
    fetchPaginationData(currentPage, perPage); // Pass current page and records per page
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchPaginationData(page);
  };

  // Handler for changing the number of rows per page
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    fetchPaginationData(page, newPerPage);
  };

  // useEffect(() => {
  // if (!searchQuery.trim()) {
  // setIsSearchClicked(false); // Reset flag when search query is cleared
  // }
  // }, [searchQuery]);

  useEffect(() => {
    if (searchQuery.trim() || isSearchClicked) {
      fetchPaginationData(currentPage, perPage); // Trigger search results fetching
    } else {
      setIsSearchClicked(false);
      fetchPaginationData(currentPage); // Fetch all data if search is empty
    }
  }, [isSearchClicked, currentPage, perPage, searchQuery]); // Depend on search query, page number, and records per page

  useEffect(() => {
    console.log("updated");
    async function getJobs() {
      dispatch(setLoadingBarStatus({ show: true })); // Show loading bar

      try {
        console.log("project", project);
        const response = await fetch(`/api/jobs/${project}`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const jobsResponse = await response.json();
        if (jobsResponse.status === 200) {
          setJobData(jobsResponse.data.projects[0].jobs);
          setRecords(jobsResponse.data.projects[0].jobs);
          setTotalRows(jobsResponse.data.totalJobs);
          // setFilteredRecords(jobsResponse.data.projects[0].jobs);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
        // Optionally handle errors (e.g., show a toast notification or update state with an error message)
      } finally {
        dispatch(setLoadingBarStatus({ show: false })); // Hide loading bar
      }
    }
    getJobs();
  }, [project, dispatch, update]);

  useEffect(() => {
    setRecords([...jobData]);
  }, [jobData]);

  return (
    <div className="font-fontFamily relative">
      {/* use navbar component */}
      <div className="mb-10">
        <Navbar />

      </div>
      <div className="fle">
        <div className="flex">
          <img
            src={Back}
            className="h-5 mt-10 ml-10 cursor-pointer"
            alt="back"
            onClick={() => navigate(`/home`)}
          ></img>
          <h1 className="font-bold mt-9 ml-2"></h1>
        </div>
        <div className="flex justify-between">
          <h1 className="mt-10 ml-10 text-lg font-semibold font-fontFamily">
            {/* include project name and katapult id */}
            Lumos
          </h1>
        </div>
      </div>

      <div className=" flex justify-between">
        <div className="flex mt-10 ml-10">
          <div>
            <h1 className="mt-2 mr-4 text-lg font-semibold"> Jobs </h1>
          </div>
          <div>
            <img
              src={line}
              alt="line"
              className="h-5 mt-3 mr-8 cursor-pointer"
            ></img>
          </div>
          <div>
            <div className="flex p-2 border rounded-lg shadow-xl ">
              <img className="h-5 cursor-pointer" alt="search" src={Search}></img>
              <div>
                <img src={line} alt="line" className="h-5 "></img>
              </div>
              <div>
                {/* make a component for search */}
                <input
                  type="text"
                  className="focus:outline-none"
                  placeholder="Type for search"
                  value={currentSearchQuery}
                  onChange={(e) => setCurrentSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex ml-9">
            <button
              onClick={handleSearch}
              className=" text-blue-700 font-bold rounded-full border-solid border-2 border-blue-600 shadow-xl px-5 cursor-pointer mr-5 "
            >
              Search
            </button>
          </div>
          <div className="flex">
            <button
              className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 text-white rounded-full shadow-xl px-5 cursor-pointer"
              onClick={excelFileUpload}
            >
              Upload Excel
            </button>
          </div>
        </div>
        {/* <div className="flex">
<button
className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 text-white rounded-full shadow-xl px-5 cursor-pointer"
onClick={excelFileUpload}
>
Upload Excel
</button>
</div> */}
        <div className="flex mt-10 mr-10">
          <div className="flex ml-5 ">
            {records.length > 0 && (
              <button
                className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 text-white rounded-full shadow-xl px-5 cursor-pointer"
                onClick={() => {
                  downloadInvoicesByProject(project);
                }}
              >
                Download All
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="ml-10 mr-10 mt-10 ">
        <DataTable
          columns={columns}
          //data={filteredRecords}
          data={records}
          selectableRows
          onSelectedRowsChange={toggleSelection}
          sortable
          customStyles={customStyles}
          pagination
          paginationServer // Enable server-side pagination
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          paginationPerPage={perPage} // Show per page setting
          paginationDefaultPage={currentPage}
        />
      </div>
      <div>
        {/* make it big */}
        {isModalOpen ? (
          <ReadExcel
            data={exceldata}
            uploadAndGenerateInvoice={uploadAndGenerateInvoice}
            close={closeModel}
          />
        ) : null}
      </div>
      {requestId ? (
        <InvoiceStatus
          requestId={requestId}
          setRequestId={setRequestId}
          setUpdate={() => {
            setUpdate(!update);
            setIsModalOpen(false);
            setRequestId(null);
          }}
        />
      ) : null}
    </div>
  );
};
export default Jobs;

