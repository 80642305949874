import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setEmail, setPassword, setAuthentication,  } from '../../../store/authSlice';
import { username, key1, companyLogo, Eyeon, Vector, line, keynew, usericon, HC_company_logo, HC_top_logo} from '../../../assets';
import useAuth from "../../../hooks/useAuth";

        
function Login() {
  const location = useLocation();
  console.log(location);
  let from = location.state?.from?.pathname || "/home";
  console.log(from);
  const dispatch = useDispatch();
  const { saveToken, clearToken } = useAuth();
  const { email, password, isAuthenticated, error } = useSelector(
    (state) => state.auth
  );
  const [visible, setVisible] = React.useState(false);
  const navigate = useNavigate();

  const login = ({ email, password }) => async (dispatch) => {
    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "username": email, "password": password }),
      });

      const res = await response.json();

      if (response.ok) {
        if (res && res.data.accessToken) {
          saveToken(res.data.accessToken); // Save token using useAuth
        } else {
          dispatch(setAuthentication({
            isAuthenticated: false,
            error: 'Invalid response data. Token not found.',
          }));
        }
      } else {
        const errorMessage = res?.error || 'An error occurred during login.';
        dispatch(setAuthentication({ isAuthenticated: false, error: errorMessage }));
      }
    } catch (error) {
      console.error('Login error:', error);
      dispatch(setAuthentication({
        isAuthenticated: false,
        error: 'An error occurred. Please try again later.',
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
    // dispatch(setEmail(''))
    dispatch(setPassword(''))
    
  };

  // Redirect to home page when authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate(from);
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-20 font-fontFamily">
      <form
        action=""
        onSubmit={handleSubmit}
        className="bg-white p-11 rounded shadow-2xl w-90 shadow-gray-700 px-18"
      >
        <img className="px-20 h-10" src={HC_company_logo} alt="hcrobologo" />
        <h2 data-testid="login-name" className="text-xl mt-16 mb-6 font-bold">
          Login
        </h2>
        <div className="mb-4 pt-4">
          <div className="flex items-center border border-gray-300 rounded-lg py-2 px-2 text-grey-700 focus:outline-none">
            <img className="h-6" src={usericon} alt="username" />
            <img className="h-5" src={line} alt="line" />
            <input
              type="text"
              id="Email"
              placeholder="Email"
              value={email}
              onChange={(e) => dispatch(setEmail(e.target.value))}
              className=" w-72 py-1 pl-2 focus:outline-none bg-white"
              required
            />
          </div>
        </div>

        <div className="mb-4 pt-4">
          <div className="flex items-center border border-gray-300 rounded-lg appearance-none py-2 px-2 text-grey-700 focus:outline-none">
            <img className="h-6" src={keynew} alt="key" />
            <img className="h-5" src={line} alt="line" />
            <input
              data-testid="pswd"
              type={visible ? 'text' : 'password'}
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => dispatch(setPassword(e.target.value))}
              className='w-64 py-1 pl-2 focus:outline-none bg-white'
              required
            />
            <div onClick={() => setVisible(!visible)}>
              <div className="ml-6 object-cover cursor-pointer">
                {visible ? (
                  <img className="h-5 w-5 object-cover" src={Eyeon} alt="eyeon" />
                ) : (
                  <img src={Vector} alt="eyeoff" className="h-5 w-5" />
                )}
              </div>
            </div>
          </div>
        </div>
        {error && <p className="text-red-500 text-sm">{error}</p>}
        <div className="text-blue-500 text-sm pt-6">
        </div>
        <button
          data-testid="Loginbutton"
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white py-2 w-full mt-8 mb-8"
        >
          Login
        </button>
      </form>
    </div>
  );
}

export default Login;
