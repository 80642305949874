import React, { useState, useEffect } from 'react'
export default function DropDown(props) {
    const label = props.label || "";
    const options = props.options || { label: "choose", value: "" };
    const disabled = props.disabled || false;
    const error = props.error;
    const className = props?.className
    const [option, setOption] = useState(props.value);

    useEffect(() => {
        setOption(props.value);
    }, [props.value])
    return (
        <div className='flex-c gap-5 align-start width-100'>
            <span className={`body2${error ? " error" : ""}`}>{label}</span>
            <select className={className ? className : ""} disabled={disabled} value={option ? option : "None"} style={{ width: "100%" }} onChange={props.onChange}>
                {
                    options.length === 0 ? null :
                        options.map((option, index) => {
                            return <option key={index} value={option.value} >{option.label}</option>
                        })
                }
            </select>
            <span className='body2 error'>{error}</span>

        </div>
    )
}
