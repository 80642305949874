import React from "react";

import { useNavigate } from "react-router-dom";
import { Back } from "../../../assets";
import { Toast } from "../../../assets";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../utilityComponents/Toaster";
import ToasterCheck from "../../utilityComponents/ToasterCheck";
import ToastTimeout from "../../utilityComponents/ToastTimeout";

import { useParams } from "react-router-dom";
const Headerjobs = () => {
  const navigate = useNavigate();
  const { jobId } = useParams()

  return (
    <div className="font-fontFamily ">
      <div className="flex">
        <img
          src={Back}
          className="h-5 mt-14 ml-10 cursor-pointer"
          alt="back"
          onClick={() => navigate(`/jobs/Lumos`)}
        ></img>
        <h1 className="font-bold mt-9 ml-2"></h1>
      </div>
      <div>
        <div className="flex justify-between mr-10 ">
          <h1 className="font-bold text-lg ml-10 mt-10 mb-2">Jobs {jobId}</h1>
        </div>
      </div>
    </div>
  );
};
export default Headerjobs;
